<template>

  <div class="content md" v-if="buddhism">
    <a-result status="success" title="登记成功！">
      <template #extra>
        <a-button key="back" type="primary" @click="$router.back()">返回工作台</a-button>
        <a-button key="detail" @click="$router.replace({name: 'BuddhismDetail', params: {id: buddhism.id}})">查看详情</a-button>
      </template>
    </a-result>
  </div>
  <div class="content md flex-box vertical" v-else>
    <h3 class="buddha-add-title">
      <a-icon type="left" class="buddha-select-back" @click="reset" v-show="temp"/>
      {{temp ? `${temp.title}登记` : '选择佛事/法会登记'}}
      <div class="buddha-history" v-show="temp"><span>历史数据</span><a-switch v-model="history"/></div>
    </h3>
    <div class="flex-grow scroll-area" v-show="!temp">
      <div class="buddha-type-select">
        <a-card class="buddha-cat-area" :title="cat.title" v-for="cat in catList" :key="cat.key">
          <div class="buddha-cat-types">
            <div class="buddha-type-item" @click="selectType(t)" v-for="t in cat.list" :key="t.key">
              <div class="buddha-type-icon"
                   :style="{backgroundImage: `url(${require('../../assets/buddha/fs_'+ t.key + '.png')})`}"></div>
              <div class="buddha-type-name">{{t.title}}</div>
            </div>
          </div>
        </a-card>
      </div>
    </div>
    <template v-if="temp">
      <div class="buddha-select-tip" v-if="info === false">尚未设置{{temp.title}}，请联系管理员设置</div>
      <div class="buddha-select-tip" v-else-if="info && info.is_local != 1">{{temp.title}}尚未开启线下登记，请联系管理员开启</div>
      <buddhism-form class="flex-grow buddha-add-area" :history="history" :buddha="info" @update="handleUpdate" v-else-if="info"></buddhism-form>
    </template>
  </div>
</template>

<script>
    import {getBuddhaOfflineTypeList} from "../../common/buddha/type";
    import config from "../../common/js/config";
    import buddhismForm from "../../components/buddha/buddhism-form";
    export default {
        name: "BuddhismAdd",
        components: {
            buddhismForm
        },
        data() {
            return {
                catList: getBuddhaOfflineTypeList(),
                temp: null,
                info: null,
                buddhism: null,
                history: false,
                host: config.host
            }
        },
        methods: {
            reset() {
                this.temp = null;
                this.info = null;
                this.form = {};
                this.subTypeList = [];
            },
            handleUpdate(buddhism) {
                this.buddhism = buddhism;
            },
            selectType(t) {
                this.temp = t;
                this.getTempConfig();
            },
            getTempConfig() {
                let temp = this.temp;
                let url = `/admin/fs-temp?pageSize=1&expand=common&filter[fs_type]=${temp.key}`;
                this.$axios(url).then(res => {
                    if (res.data.length > 0) {
                        let info = res.data[0];
                        this.info = info;
                    } else {
                        this.info = false;
                    }
                });
            },
        }
    }
</script>

<style scoped lang="less">
  .buddha-add-title {
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 6px;
    border-bottom: var(--border);
    font-size: 18px;
    text-align: center;
  }
  .buddha-add-area {
    min-height: 0;
  }
  .buddha-select-back {
    position: absolute;
    top: 4px;
    left: 10px;
  }
  .buddha-history {
    display: flex;
    align-items: center;
    position: absolute;
    top: 4px;
    right: 10px;
    font-size: 14px;
    span {
      margin-right: 8px;
    }
  }
  .buddha-cat-area {
    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }

  .buddha-cat-types {
    display: flex;
  }

  .buddha-type-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    width: 20%;
    cursor: pointer;

    &:hover {
      background-color: @background-color-light;
    }
  }

  .buddha-type-icon {
    width: 48px;
    height: 48px;
    background: center no-repeat;
    background-size: contain;
  }

  .buddha-type-name {
    margin-top: 10px;
    font-size: 16px;
  }

  .buddha-select-tip {
    margin-top: 24px;
    color: @text-color-secondary;
    text-align: center;
    font-size: 16px;
  }
  .buddha-add-form {
    padding: 10px 30px;
  }
  .add-success-title {
    margin: 40px 0;
    font-size: 24px;
    text-align: center;
  }
  .buddhism-qr {
    margin: 0 auto;
    width: 300px;
    height: 300px;
  }
  .buddhism-qr-img {
    width: 100%;
    height: 100%;
  }
  .buddhism-button {
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    width: 300px;
  }
</style>
